   
body {
  margin: 0;
  overflow-y: scroll;
}

* {
  font-family: system-ui;
}
.fourImagesContainerRef {
  display: flex;
  height: 100vh;
}

.imageCardContainer,
.specialitiesLanginImage {
  position: relative;
  animation-name: appear;
  animation-duration: 1s;
}

.imageCardContainer h3 {
  color: #000000;
  text-decoration: none;
  position: absolute;
  bottom: 12%;
  left: 4%;
  border-bottom: 4px solid #a52024;
  padding: 5px;
  background: #ffffff36;
  border-radius: 5px;
}

.imageCardContainer:hover {
  cursor: pointer;
}

.specialitiesLanginImageContainer {
  position: relative;
}

.specialitiesLanginImageContainer h1 {
  font-size: 72px;
  color: #000000;
  text-decoration: none;
  position: absolute;
  bottom: 51%;
  left: 3%;
  margin-bottom: 1%;
}

.specialitiesLanginImage {
  width: 100vw;
  height: 100vh;
}
.specialitiesLanginImageContainer .bottomBorder {
  width: 10vw;
  position: absolute;
  border-bottom: 8px solid #a52024;
  bottom: 50%;
  left: 3%;
}
.specialitiesLanginImageContainer .arrowDiv img {
  width: 4%;
  position: absolute;
  bottom: 5%;
  left: 50%;
  animation-name: arrowAnimation;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}

@keyframes arrowAnimation {
  25% {
    bottom: 1%;
    left: 50%;
  }
  50% {
    bottom: 5%;
    left: 50%;
  }
  75% {
    bottom: 1%;
    left: 50%;
  }
  100% {
    bottom: 5%;
    left: 50%;
  }
}

.imageCardContainer div {
  display: none;
  color: #fbfbfb;
  text-decoration: none;
  position: absolute;
  bottom: 10%;
  left: 4%;
  font-size: small;
  font-weight: bold;
  animation-name: appear;
  animation-duration: 2s;
  background: #00000085;
  border-radius: 3px;
  padding: 3px;
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 100%;
  }
}

.navBarContainer,
.headerAnimationReverted {
  background: #00000059;
}

.navBarContainer,
.headerAnimation,
.headerAnimationReverted {
  position: fixed;
  width: 100%;
  display: flex;
  z-index: 2;
}

.headerAnimation {
  animation-name: header;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.headerAnimationReverted {
  animation-name: headerrevert;
  animation-duration: 1s;
}

@keyframes header {
  from {
    background: #00000059;
  }
  to {
    background: #fbfbfb;
  }
}

@keyframes headerrevert {
  from {
    background: #fbfbfb;
  }
  to {
    background: #00000059;
  }
}

.imageCard {
  width: 50vw;
  height: 100%;
}

/* .imageCard:hover {
  width: 40vw;
  animation-name: anime;
  animation-duration: 1s;
}
.imageCard:not(:hover) {
  width: 33.33333vw;
  animation-name: out;
  animation-duration: 1s;
}
@keyframes anime {
  from {
    width: 33.33333vw;
  }
  to {
    width: 40vw;
  }
} */
@keyframes out {
  from {
    width: 40vw;
  }
  to {
    width: 33.33333vw;
  }
}

.logo {
  display: flex;
  flex: 0.5;
  color: #f3f3f3;
  margin-left: 3%;
  align-items: center;
}

.logoAnimation,
.logoAnimationReverted,
.headerLogo {
  padding: 1%;
  cursor: pointer;
}

.logoAnimation {
  animation-name: appear;
  animation-duration: 1s;
}

.logoAnimationReverted {
  animation-name: appear;
  animation-duration: 1s;
}

.headerLogo,
.logoAnimationReverted {
  width: 16%;
}

.logoAnimation {
  width: 16%;
}

.navItemsContainer {
  display: flex;
  justify-content: space-around;
  flex: 1;
  align-self: center;
  align-items: center;
  height: 6vh;
}

.navItemsContainer button {
  background: #a52024;
  border: none;
  color: #fbfbfb;
}

.navItemsContainer ul {
  visibility: hidden;
  position: absolute;
  background-color: #f3f3f3;
  padding: 18px;
  min-width: max-content;
  border-radius: 2px;
}

.navItemsContainer li {
  color: #525252;
  list-style-type: none;
  padding: 3%;
  width: max-content;
  border-bottom: 0.5px solid #525252;
}
.navItemsContainer li:hover,
.navItemsContainer ul:hover,
.navItemsContainer div:hover {
  color: #a52024 !important;
}

.navItemsContainer li:last-child {
  border-bottom: none;
}

.navItemsContainer div,
.specialitiesMenue {
  font-size: 15px;
  color: #f3f3f3;
  text-decoration: none;
  height: max-content;
  transition: border-bottom 0.1s;
  cursor: pointer;
}

.specialitiesMenue {
  font-weight: 500;
}
.navItemsContainer a:visited {
  color: #f3f3f3;
}

.navItemsContainer div:hover,
.specialitiesMenue:hover {
  color: #a52024;
}

.imageCardContainer a:visited {
  color: #a52024;
}

.contactinfo {
  position: absolute;
  bottom: 12%;
  left: 34%;
  color: #f3f3f3;
}

.introSectionContainer {
  display: flex;
  margin-left: 10%;
  margin-top: 10%;
}

.introSectionContainer img {
  flex: 1;
  width: 20%;
  animation-name: circle;
  animation-duration: 30s;
  animation-iteration-count: infinite;
}

@keyframes circle {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(360deg);
  }

  100% {
  }
}

.introTextContainer {
  flex: 2;
  padding: 10%;
  text-align: start;
}

.introTextContainer h1 {
  font-size: 72px;
  margin-bottom: 5%;
}

.introTextContainer h2 {
  margin-bottom: 1%;
}

.introTextContainer p {
  word-break: break-all;
}

.quoteForm {
  text-align: center;
}

.whoWeAreContainer {
  position: absolute;
  bottom: 52%;
  left: 25%;
  color: white;
}

.socialMediaIcons {
  position: absolute;
  bottom: 63%;
  left: 34%;
}

.socialMediaIcons img {
  height: 3%;
  width: 3%;
  margin-left: 1%;
  cursor: pointer;
}
.socialMediaIcons img:nth-child(1) {
  margin-left: 0;
}

.mobileMenuContainer {
  display: flex;
  padding: 1%;
}

.mobileMenuContainer a {
  padding-top: 3%;
  margin-right: 3%;
}

.mobileLogoContainer {
  flex: 1;
}

.mobileLogo {
  width: 25%;
  margin-left: 5%;
}

.burgerMenu {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.burgerMenu img {
  width: 19%;
  margin-right: 5%;
}

.closeButton {
  display: flex;
  flex: 1;
  padding-top: 3%;
  margin-left: 3%;
  align-items: center;
}

.closeButton img {
  width: 35px;
  height: 35px;
}

.phone {
  height: 60px;
}

.dropdownMobileContainer {
  display: flex;
  flex-direction: column;
  height: 93vh;
  align-items: center;
}

.dropdownMobileContainer div {
  flex: 1;
  text-align: center;
  display: flex;
}

.dropdownMobileContainer div span {
  align-self: center;
  font-size: 25px;
  border-bottom: 1px solid antiquewhite
}

.dropdownMobileContainer button {
  flex: 0.3;
  margin-bottom: 5%;
  width: 80%;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */

  * {
    animation-name: fudge;
    animation-duration: unset;
  }

  .fourImagesContainerRef {
    flex-direction: column;
  }

  .imageCard {
    width: 100%;
  }
  .imageCard:hover {
    width: 100%;
    animation-name: none;
    animation-duration: unset;
  }

  .imageCard:not(:hover) {
    width: 100%;
    animation-name: none;
    animation-duration: unset;
  }

  .imageCardContainer div {
    animation-name: none;
    animation-duration: unset;
  }

  .imageCardContainer,
  .specialitiesLanginImage {
    flex: 1;
    animation-name: none;
    animation-duration: unset;
  }

  .headerLogo,
  .logoAnimationReverted,
  .logoAnimation {
    width: 64%;
  }

  .navBarContainer,
  .headerAnimationReverted {
    background: #000000db;
    height: 15vh;
  }

  .navItemsContainer {
    height: 14vh;
    flex-wrap: wrap;
  }

  .navItemsContainer div,
  .specialitiesMenue {
    margin: 2px;
  }

  .specialitiesLanginImageContainer h1 {
    bottom: 71%;
    left: 0%;
    font-size: 66px;
    padding: 5px;
    background: #ffffff36;
    border-radius: 5px;
  }

  .specialitiesLanginImageContainer .bottomBorder {
    bottom: 70%;
    left: 0%;
    width: 60vw;
  }

  .contactinfo {
    bottom: 0%;
    left: 3%;
  }

  .specialitiesLanginImageContainer .arrowDiv img {
    width: 12%;
  }

  .whoWeAreContainer {
    bottom: 43%;
    left: 0;
    height: 50vh;
    width: 100%;
  }

  .whoWeAreContainer p {
    width: 100vw !important;
    text-align: justify;
  }

  .whoWeAreContainer h1,
  .whoWeAreContainer p {
    padding: 10px;
  }

  .socialMediaIcons {
    bottom: 82%;
    left: 34%;
  }

  .socialMediaIcons img {
    height: 15%;
    width: 15%;
    margin-left: 5%;
  }

  .introTextContainer h1 {
    font-size: 20px !important;
    margin-bottom: 15% !important;
  }
  .introTextContainer h2 {
    font-size: 15px !important;
    font-weight: bold;
    margin-bottom: 5% !important;
  }
  
}